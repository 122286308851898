body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --pink: #fee1da;
  --pink-end: #ffdfd3;
  --blue: #d4f3ff;
  --blue-end: #bbacc1;
  --purple: #f5d4ff;
  --purple-end: #898392;
  --gray: #898392;
  --gray-end: #898392;
  --green: #deffd4;
  --green-end: #bce1db;
  --white: #fff9ed;
  --white-end: #f2ece0;
  --white-shadow-1: #4d473b3f;
  --white-shadow-2: #4d473b39;
  /* --white: #e0e5ec;
  --white-end: #e0e5ec;
  --white-shadow-1: rgb(163, 177, 198, 0.6);
  --white-shadow-2: rgba(163, 177, 198, 0.5); */
  --black: #1a0000;
  --head-font: 'Calligraffitti';
  --detail-font: 'Open Sans';
  --extra-font: 'Bad Script';
  --grass: #a1ffce;
  --sun: #faffd1;
}

h1 {
  font-family: 'Calligraffitti', cursive;
  font-family: var(--head-font), cursive;
}

h2,
.text-detail {
  font-family: 'Open Sans', sans-serif;
  font-family: var(--detail-font), sans-serif;
}

.pink {
  background-color: #fee1da;
  background-color: var(--pink);
  background: linear-gradient(to bottom, #fee1da 0%, #ffdfd3 100%);
  background: linear-gradient(to bottom, var(--pink) 0%, var(--pink-end) 100%);
  color: #1a0000;
  color: var(--black);
  background-image: url(/static/media/pink.a31e751f.jpg);
  /* background-image: url('./img/pink-hd.jpg'); */
  background-size: cover;
}

.white {
  background-color: #fff9ed;
  background-color: var(--white);
  background: linear-gradient(
    to bottom,
    #fff9ed 0%,
    #f2ece0 100%
  );
  background: linear-gradient(
    to bottom,
    var(--white) 0%,
    var(--white-end) 100%
  );
  background-image: url(/static/media/white.4639decb.jpg);
  color: #1a0000;
  color: var(--black);
}

.white-marble {
  background-color: #fff9ed;
  background-color: var(--white);
  background: linear-gradient(
    to bottom,
    #fff9ed 0%,
    #f2ece0 100%
  );
  background: linear-gradient(
    to bottom,
    var(--white) 0%,
    var(--white-end) 100%
  );
  background-image: url(/static/media/white-marble.49be57b2.jpg);
  background-size: cover;
  color: #1a0000;
  color: var(--black);
}

.blue {
  background-color: #d4f3ff;
  background-color: var(--blue);
  background: linear-gradient(to bottom, #d4f3ff 0%, #bbacc1 100%);
  background: linear-gradient(to bottom, var(--blue) 0%, var(--blue-end) 100%);
  color: #1a0000;
  color: var(--black);
}

.gray {
  background-color: #898392;
  background-color: var(--gray);
  background: linear-gradient(to bottom, #898392 0%, #898392 100%);
  background: linear-gradient(to bottom, var(--gray) 0%, var(--gray-end) 100%);
  color: #1a0000;
  color: var(--black);
}

.green {
  background-color: #deffd4;
  background-color: var(--green);
  background: linear-gradient(
    to bottom,
    #deffd4 0%,
    #deffd4 90%,
    #bce1db 100%
  );
  background: linear-gradient(
    to bottom,
    var(--green) 0%,
    var(--green) 90%,
    var(--green-end) 100%
  );
  color: #1a0000;
  color: var(--black);
}

.purple {
  background-color: #f5d4ff;
  background-color: var(--purple);
  background-image: linear-gradient(
    to bottom,
    #f5d4ff '90%',
    #898392 '100%'
  );
  background-image: linear-gradient(
    to bottom,
    var(--purple) '90%',
    var(--purple-end) '100%'
  );
  color: #1a0000;
  color: var(--black);
}

.page {
  /* overflow: hidden; */
}

.page-top {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(/static/media/main.800bdd0f.jpg);
  background-color: #fee1da;
  background-color: var(--pink);
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 40px - 0.5rem);
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-top h1 {
  font-size: 8.5vw;
}

.spot {
  position: relative;
  top: -40px;
}

.bottom-text {
  max-width: 512px;
  margin: auto auto;
  position: absolute;
  text-align: center;
  color: #ffffff;
  mix-blend-mode: difference;
  top: 50%;
  right: 46%;
}

.top-text {
  max-width: 512px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #ffffff;
  mix-blend-mode: difference;
}

.livestream {
  max-width: 512px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #ffffff;
  z-index: 1000;
}

.top-text h1,
.top-text h2 {
  font-family: 'anandfont';
}

.top-text h2 {
  font-size: 6vw;
}

.moving-clouds {
  position: absolute;
  z-index: 1050;
  bottom: 0;
  left: 0;
  width: 200vw;
  height: 30vh;
  animation: cloudLoop 80s linear infinite;
  background-image: url(/static/media/clouds.61c4e000.png);
}

@keyframes cloudLoop {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.navbar {
  background-color: #fee1da;
  background-color: var(--pink);
  font-family: 'Open Sans';
  font-family: var(--detail-font);
}

.navbar-nav > a {
  border-right: 1px solid #000;
}

.navbar-nav > a:last-child {
  border: none;
}

.story {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.story-image > div:nth-child(1) {
  width: 100%;
  height: 100%;
  position: absolute;
}

/*
for grain static effect
*/
.story-image-static {
  width: 110%;
  height: 100%;
  position: absolute;
  top: -5%;
  left: -5%;
  opacity: 0.11;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/662025/grain.jpg')
    repeat center center;
  animation: grain 0.5s steps(1) infinite;
}

/* 2560px */

@media (max-width: 2560px) and (min-height: 3080px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 2560px) and (max-height: 3080px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

/* 1920px */

@media (max-width: 1920px) and (min-height: 2300px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 1920px) and (max-height: 2300px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}
/* 1680px */

@media (max-width: 1680px) and (min-height: 1700px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 1680px) and (max-height: 1700px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 1680px) and (max-height: 916px) {
  .story-image > div:nth-child(1) {
    height: 68%;
    top: 16%;
  }
}

/* 1440px */

@media (max-width: 1440px) and (min-height: 1720px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 1440px) and (max-height: 1720px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 1440px) and (max-height: 900px) {
  .story-image > div:nth-child(1) {
    height: 59%;
    top: 20%;
  }
}

@media (max-width: 1440px) and (max-height: 750px) {
  .story-image > div:nth-child(1) {
    height: 70%;
    top: 15%;
  }
}

@media (max-width: 1440px) and (max-height: 540px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}
/* 1200px */

@media (max-width: 1200px) and (min-height: 1440px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 1200px) and (max-height: 1440px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 1200px) and (max-height: 930px) {
  .story-image > div:nth-child(1) {
    height: 48%;
    top: 26%;
  }
}

@media (max-width: 1200px) and (max-height: 650px) {
  .story-image > div:nth-child(1) {
    height: 70%;
    top: 15%;
  }
}

@media (max-width: 1200px) and (max-height: 380px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}
/* 992px */

@media (max-width: 992px) and (min-height: 1181px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 992px) and (max-height: 1181px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 992px) and (max-height: 650px) {
  .story-image > div:nth-child(1) {
    height: 56%;
    top: 22%;
  }
}

@media (max-width: 992px) and (max-height: 550px) {
  .story-image > div:nth-child(1) {
    height: 66%;
    top: 17%;
  }
}

@media (max-width: 992px) and (max-height: 380px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

/* 768px */

@media (max-width: 768px) and (min-height: 915px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 768px) and (max-height: 915px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 768px) and (max-height: 600px) {
  .story-image > div:nth-child(1) {
    height: 48%;
    top: 26%;
  }
}

@media (max-width: 768px) and (max-height: 412px) {
  .story-image > div:nth-child(1) {
    height: 68%;
    top: 16%;
  }
}

@media (max-width: 768px) and (max-height: 360px) {
  .story-image > div:nth-child(1) {
    height: 76%;
    top: 12%;
  }
}
/* 576px */
@media (max-width: 576px) and (min-height: 690px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 576px) and (max-height: 690px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 576px) and (max-height: 376px) {
  .story-image > div:nth-child(1) {
    height: 56%;
    top: 22%;
  }
}

@media (max-width: 576px) and (max-height: 321px) {
  .story-image > div:nth-child(1) {
    height: 66%;
    top: 17%;
  }
}

/* 414px */
@media (max-width: 414px) and (min-height: 690px) {
  .story-image > div:nth-child(1) {
    height: 100%;
    top: 0%;
  }
}

@media (max-width: 414px) and (max-height: 690px) {
  .story-image > div:nth-child(1) {
    height: 46%;
    top: 27%;
  }
}

@media (max-width: 414px) and (max-height: 376px) {
  .story-image > div:nth-child(1) {
    height: 56%;
    top: 22%;
  }
}

@media (max-width: 414px) and (max-height: 321px) {
  .story-image > div:nth-child(1) {
    height: 66%;
    top: 17%;
  }
}

.story-stage {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center center;
  background-image: url(/static/media/stage1.8e1bf885.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.story-main {
  position: relative;
  background: black;
  width: 100%;
  height: 100%;
}

.story-intro {
  top: 0;
  left: 0;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: middle;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story-curtain {
  width: 50%;
  height: 100%;
  position: absolute;
  transition: all 1s ease;
}

.story-curtain-l {
  transform: translateX(-100%);
}

.story-curtain-r {
  transform: translateX(0%);
}

.story-curtain-lt {
  transform: translateX(-200%);
}

.story-curtain-rt {
  transform: translateX(+100%);
}

.plane {
  width: 80%;
  height: 80vh;
  margin: 10vh auto;
  overflow: hidden;
}

.plane > img {
  display: none;
}

#canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.story-image {
  position: relative;
  width: 100%;
  height: 100%;

  margin: auto;
  background-position: center center;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.story-video {
  height: 100%;
  width: 100%;
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0, 0);
  }

  10% {
    transform: translate(-1%, -1%);
  }

  20% {
    transform: translate(1%, 1%);
  }

  30% {
    transform: translate(-2%, -2%);
  }

  40% {
    transform: translate(3%, 3%);
  }

  50% {
    transform: translate(-3%, -3%);
  }

  60% {
    transform: translate(4%, 4%);
  }

  70% {
    transform: translate(-4%, -4%);
  }

  80% {
    transform: translate(2%, 2%);
  }

  90% {
    transform: translate(-3%, -3%);
  }
}

.events {
  text-align: center;
}

.event {
  position: relative;
  padding: 3vh 2vw;
  margin: 5vh 0;
  background-image: url(/static/media/whitenoise.28ac962f.png);
  background-color: #fff9ed;
  background-color: var(--white);

  box-shadow: 9px 9px 16px #4d473b3f,
    -9px -9px 16px #4d473b39;

  box-shadow: 9px 9px 16px var(--white-shadow-1),
    -9px -9px 16px var(--white-shadow-2);
}

.event-left,
.event-right {
  content: '';
  position: absolute;
  height: 100%;
  width: 6px;
  top: 0;
}

.event-right {
  right: -6px;
  background: radial-gradient(
      circle,
      transparent,
      transparent 50%,
      #fff9ed 50%,
      #fff9ed 100%
    ) -2px -2px / 16px 16px repeat-y;
  background: radial-gradient(
      circle,
      transparent,
      transparent 50%,
      var(--white) 50%,
      var(--white) 100%
    ) -2px -2px / 16px 16px repeat-y;
}

.event-left {
  content: '';
  position: absolute;
  height: 100%;
  width: 6px;
  top: 0;
  left: -6px;
  background: radial-gradient(
      circle,
      transparent,
      transparent 50%,
      #fff9ed 50%,
      #fff9ed 100%
    ) -8px -8px / 16px 16px repeat-y;
  background: radial-gradient(
      circle,
      transparent,
      transparent 50%,
      var(--white) 50%,
      var(--white) 100%
    ) -8px -8px / 16px 16px repeat-y;
}

.event-border {
  width: 98%;
  height: 96%;
  right: 1%;
  top: 2%;
  position: absolute;

  border-top-style: solid;
  border-top-width: 2rem;
  border-right-style: solid;
  border-right-width: 2rem;
  border-bottom-style: solid;
  border-bottom-width: 2rem;
  border-left-style: solid;
  border-left-width: 2rem;

  -o-border-image: url(/static/media/tix-b.ecf3de35.svg);
  border-image-source: url(/static/media/tix-b.ecf3de35.svg);
  border-image-slice: 135 220;
  border-image-width: 35px 50px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
}

.map-parent {
  text-align: end;
  border-right: #1a0000;
  border-right: var(--black);
  border-right-style: dotted;
}

.map-info {
  text-align: center;
  padding: 1vh 1vw;
}

.map-overlay {
  z-index: 2;
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
  border-width: 2px;
  border-style: inset;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
}

.section {
  width: 100vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.section h2 {
  font-size: 2em;
  padding: 1vh 0;
  color: #1a0000;
  color: var(--black);
  font-family: 'Bad Script';
  font-family: var(--extra-font);
}

.todo {
  position: relative;
  margin: auto;
}

.hotel .card-img-top {
  width: inherit;
}

.card-img-top {
  max-height: 300px;
  object-fit: cover;
}

.names {
  background-color: #fee1da;
  background-color: var(--pink);
  min-height: 60vh;
  position: relative;
  overflow: hidden;
}

.postpone {
  color: #1a0000;
  color: var(--black);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
  padding: 1vh 1vw;
}

.postpone:before {
  content: '';
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}

.name-text {
  color: #ffffff;
  position: relative;
}

.name-left,
.name-right {
  height: 100%;
  position: absolute;
  top: 0;
}
.name-left {
  transform: scaleX(-1);
  left: -250px;
}

.name-right {
  right: -250px;
}

.name-text h1 {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.09),
    -1px -1px 0 rgba(255, 220, 0, 0.67);
  background: linear-gradient(0deg, #fff65c, #9a8000);
  background-size: 200% 200%;
  animation: AnimationName 3s ease infinite;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-size: 5em;
  padding: 1vh 1vw;
}
@keyframes AnimationName {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}

.form-label {
  font-family: 'Bad Script';
  font-family: var(--extra-font);
  font-size: 1.5em;
}

@font-face {
  font-family: 'anandfont';
  src: url(/static/media/f1.e9c9a7ba.ttf);
}

@media only screen and (max-width: 600px) {
  .event {
    padding: 45px 0;
  }
  .map-parent {
    border-right-style: hidden;
    border-bottom-style: dotted;
  }

  .name-left {
    left: -190px;
  }

  .name-right {
    right: -190px;
  }

  .map-info {
    padding: 2vh 2vw;
  }
}

.invite-button {
  font-size: 3em;
  background-color: #a1ffce;
  background-color: var(--grass);
  background-size: auto 400%;
  color: #1a0000;
  color: var(--black);
  background-image: linear-gradient(
    to bottom,
    #faffd1 0%,
    #a1ffce 10%,
    #1a0000 100%
  );
  background-image: linear-gradient(
    to bottom,
    var(--sun) 0%,
    var(--grass) 10%,
    var(--black) 100%
  );
  transition: 1s;
}

.invite-button:hover {
  color: #fff9ed;
  color: var(--white);
  background-position: bottom center;
}

.hide {
  display: none;
}

a.card-link {
  font-size: xx-large;
}

